<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching staff data
      </h4>
      <div class="alert-body">
        No user found with this staff id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'admin-staff'}"
        >
          Staff List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <div class="d-flex justify-content-end align-items-center mb-2">
        <login-as-user
          v-if="canLoginAsUser"
          :user-id="userData.user.id"
          :user-role="userData.user.role.name"
        />
      </div>
      <!-- First Row -->
      <b-row
        class="d-flex align-items-stretch"
      >
        <b-col
          cols="12"
          md="7"
          lg="6"
          xl="6"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>

        <b-col
          v-if="isSchoolModuleActive('Family') && userData.user.family_id"
          cols="12"
          md="3"
          lg="4"
          xl="4"
        >
          <user-view-family-info-card :user-data="userData.user" />
        </b-col>

        <b-col
          cols="12"
          md="2"
          lg="2"
          xl="2"
        >
          <user-view-user-plan-card />
        </b-col>
      </b-row>

      <user-information-card
        :user-data="userData.user"
        @updateUserInfo="updateStaffInfo"
      />

      <programs-list :user-data="userData" />

      <b-row
        class="d-flex align-items-stretch"
      >
        <b-col
          cols="12"
          md="7"
        />

        <b-col
          cols="12"
          md="5"
        >
          <user-view-user-groups
            :user-data="userData"
          />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import staffStoreModule from '@/views/admin/staff/staffStoreModule'
import UserViewUserInfoCard from '@/views/admin/staff/staff-profile/StaffViewUserInfoCard.vue'
import UserViewUserPlanCard from '@/views/admin/staff/staff-profile/StaffViewUserPlanCard.vue'
import UserViewUserGroups from '@/views/admin/staff/staff-profile/StaffViewUserGroups.vue'
import UserInformationCard from '@/views/components/user-information-card/UserInformationCard.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import ProgramsList from '@/views/admin/staff/staff-programs/ProgramList.vue'
import useStaffProfile from '@/views/admin/staff/staff-profile/useStaffProfile'
import UserViewFamilyInfoCard from '@/views/admin/staff/staff-profile/StaffViewFamilyInfoCard.vue'
import LoginAsUser from '@/views/components/login-as-user/LoginAsUser.vue'

export default {
  components: {
    LoginAsUser,
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserGroups,
    UserInformationCard,
    UserViewFamilyInfoCard,

    ProgramsList,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-staff'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, staffStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      updateStaff,
    } = useStaffProfile()

    store.dispatch('app-staff/fetchStaffProfile', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data.data
        if (userData.value.user.address.length === 0) userData.value.user.address = {}
        if (userData.value.user.extra_user_info.length === 0) userData.value.user.extra_user_info = {}
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
      updateStaff,
    }
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  computed: {
    canLoginAsUser() {
      return this.$can('read', this.permissionSubjects.LoginAsStaff)
    },
  },
  methods: {
    updateStaffInfo() {
      this.updateStaff(this.userData)
    },
  },
}
</script>

<style>

</style>
