import axios from '@axios'

/* eslint-disable import/prefer-default-export */
export const fetchParentsList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/parents/', { params })
  return res
}

export const fetchParent = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/parents/${id}`)
  return res
}
export const exportParents = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/parents/export/csv', { params })
  return res
}

export const fetchParentStatuses = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/parent-statuses', { params })
  return res
}

export const deleteParent = async (id: string): Promise<any> => {
  const res = await axios.delete(`/auth/parents/${id}`)
  return res
}

export const updateParent = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/parents/${params.id}`, params)
  return res
}
