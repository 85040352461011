import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const loginAsUser = queryParams => axios
    .post('/auth/login-as-user', { user_id: `${queryParams}` })
    .then(response => response.data.accessToken)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error get token',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    loginAsUser,
  }
}
