<template>
  <b-card>

    <b-form>
      <b-row>

        <b-col
          sm="12"
          class="d-flex align-items-center mb-2"
        >
          <feather-icon
            icon="UserIcon"
            size="16"
          />
          <h4 class="mb-0 ml-75">
            {{ userType }} Information
          </h4>
        </b-col>
        <hr class="mt-0 w-100">
      </b-row>

      <edit-user-info-row
        :row-label="'First Name'"
        :row-name="'first_name'"
        :row-value="userData.first_name"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'Last Name'"
        :row-name="'last_name'"
        :row-value="userData.last_name"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'Birth date'"
        :row-name="'birth_date'"
        :row-type="'datepicker'"
        :row-value="userData.extra_user_info.birth_date"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'Gender'"
        :row-name="'gender'"
        :row-value="userData.extra_user_info.gender"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :disabled="true"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'SSN'"
        :row-name="'ssn'"
        :row-value="userData.extra_user_info.ssn"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'Phone Number'"
        :row-name="'phone'"
        :row-value="userData.phone"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'Email'"
        :row-name="'email'"
        :row-value="userData.email"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <template v-if="userType !== 'Staff'">
        <b-col
          sm="12"
          class="mt-2 d-flex align-items-center mb-2 pl-0"
        >
          <feather-icon
            icon="UsersIcon"
            size="16"
          />
          <h4 class="mb-0 ml-75">
            Parent’s Information
          </h4>
        </b-col>
        <hr class="mt-0 w-100">

        <div
          v-for="parentData in userData.parents_info"
          :key="parentData.id"
        >
          <b-col
            sm="12"
            class="mt-2 d-flex align-items-center mb-2 pl-0"
          >
            <h4 class="mb-0">
              {{ capitalizeFirstLetter(parentData.relationship) }}
            </h4>
          </b-col>

          <edit-user-info-row
            :row-label="'Name'"
            :row-name="parentData.relationship+'_name'"
            :row-value="parentData.name"
            :enabled-field="enabledField"
            :edit-mode="editMode"
            :placeholder="'Name'"
            @enableEditModeFor="enableEditModeFor"
            @saveFieldChanges="saveFieldChanges"
            @updateRowValue="updateRowValue"
          />

          <edit-user-info-row
            :row-label="'Phone Number'"
            :row-name="parentData.relationship+'_phone'"
            :row-value="parentData.phone"
            :enabled-field="enabledField"
            :edit-mode="editMode"
            :placeholder="'Phone Number'"
            @enableEditModeFor="enableEditModeFor"
            @saveFieldChanges="saveFieldChanges"
            @updateRowValue="updateRowValue"
          />

          <edit-user-info-row
            :row-label="'Email'"
            :row-name="parentData.relationship+'_email'"
            :row-value="parentData.email"
            :enabled-field="enabledField"
            :edit-mode="editMode"
            :placeholder="'Email'"
            @enableEditModeFor="enableEditModeFor"
            @saveFieldChanges="saveFieldChanges"
            @updateRowValue="updateRowValue"
          />
        </div>
      </template>

      <b-col
        sm="12"
        class="mt-2 d-flex align-items-center mb-2 pl-0"
      >
        <feather-icon
          icon="MapPinIcon"
          size="16"
        />
        <h4 class="mb-0 ml-75">
          Address
        </h4>
      </b-col>
      <hr class="mt-0 w-100">

      <edit-user-info-row
        :row-label="'Address Line 1'"
        :row-name="'address_line_1'"
        :row-value="userData.address.address_line_1"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :placeholder="'Address Line 1'"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'Address Line 2'"
        :row-name="'address_line_2'"
        :row-value="userData.address.address_line_2"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :placeholder="'Address Line 2'"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'Postcode'"
        :row-name="'zip'"
        :row-value="userData.address.zip"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :placeholder="'Postcode'"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'City'"
        :row-name="'city'"
        :row-value="userData.address.city"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :placeholder="'City'"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'State'"
        :row-name="'state'"
        :row-value="userData.address.state"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :placeholder="'State'"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />

      <edit-user-info-row
        :row-label="'Country'"
        :row-name="'country'"
        :row-value="userData.address.country"
        :enabled-field="enabledField"
        :edit-mode="editMode"
        :placeholder="'Country'"
        :can-edit-user-data="canEditUserData"
        @enableEditModeFor="enableEditModeFor"
        @saveFieldChanges="saveFieldChanges"
        @updateRowValue="updateRowValue"
      />
    </b-form>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import EditUserInfoRow from '@/views/components/edit-user-info-row/EditUserInfoRow.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import capitalizeFirstLetter from '@/helpers/capitalizeFirstLetter'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BForm,

    EditUserInfoRow,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      enabledField: '',
      permissionSubjects,
    }
  },
  computed: {
    userType() {
      return this.userData.role.name === 'child' ? this.camperStudent(1) : 'Staff'
    },
    canEditUserData() {
      if (this.userData.role.name === 'child') {
        return this.$can('update', this.permissionSubjects.Child)
      }
      return this.$can('update', this.permissionSubjects.Staff)
    },
  },
  methods: {
    capitalizeFirstLetter,
    enableEditModeFor(field) {
      this.editMode = true
      this.enabledField = field
    },
    saveFieldChanges() {
      this.editMode = false
      this.enabledField = ''
      this.handleForm()
    },
    handleForm() {
      this.$emit('updateUserInfo')
    },
    updateRowValue({ rowName, value }) {
      switch (rowName) {
        case 'first_name':
          this.userData.first_name = value
          break
        case 'last_name':
          this.userData.last_name = value
          break
        case 'birth_date':
          this.userData.extra_user_info.birth_date = value
          break
        case 'gender':
          this.userData.extra_user_info.gender = value
          break
        case 'ssn':
          this.userData.extra_user_info.ssn = value
          break
        case 'phone':
          this.userData.phone = value
          break
        case 'email':
          this.userData.email = value
          break
        case 'father_name':
          this.userData.parents_info.father_name = value
          break
        case 'father_phone':
          this.userData.parents_info.father_phone = value
          break
        case 'father_email':
          this.userData.parents_info.father_email = value
          break
        case 'mother_name':
          this.userData.parents_info.mother_name = value
          break
        case 'mother_phone':
          this.userData.parents_info.mother_phone = value
          break
        case 'mother_email':
          this.userData.parents_info.mother_email = value
          break
        case 'address_line_1':
          this.userData.address.address_line_1 = value
          break
        case 'address_line_2':
          this.userData.address.address_line_2 = value
          break
        case 'zip':
          this.userData.address.zip = value
          break
        case 'city':
          this.userData.address.city = value
          break
        case 'state':
          this.userData.address.state = value
          break
        case 'country':
          this.userData.address.country = value
          break
        default:
          break
      }
    },
  },
}
</script>

<style>

</style>
