import axios from '@axios'

export const addMember = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/family/member/add', params)
  return res
}

export const updateMember = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/family/member/${params.id}/update`, params)
  return res
}

export const deleteMember = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/family/member/${id}/delete`)
  return res
}
